<template>
  <div class="box">
    <p class="text-center text_info">{{ $t('login.otp') }}</p>
    <div id="otp" class="mt-2">
      <input v-model="pin1" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin2" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin3" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin4" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <!-- <input
        v-model="pin4"
        type="text"
        maxlength="1"
        v-if="getCountryCode === '971'"
        v-on:keyup="keyMonitor"
      /> -->
    </div>

    <div class="butn">
      <button
        :class="{ game_btn: true, activeBtn: isChanged }"
        :disabled="btnDisabled"
        id="sub_btn"
        @click="verify()"
      >
        {{ btnDisabled ? $t('login.loading') : $t('login.subscribe') }}
      </button>
      <button
        :class="{ game_btn: true }"
        :disabled="btnDisabled"
        class="d-inline"
        @click="redirectToGoogle"
      >
        {{ $t('login.exit') }}
      </button>
    </div>
    <div style="font-size: 14px; margin-top: 10px">
      {{ $t('login.header') }}
    </div>
    <div style="font-size: 11px; margin-top: 10px">
      {{ $t('login.otpTrial') }}
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api'
import AlertServie from '../../services/errors'
import Vue from 'vue'
export default {
  data () {
    return {
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      // pin5: "",
      isChanged: false,
      btnDisabled: false
    }
  },
  methods: {
    redirectToGoogle () {
      window.location.href = 'https://www.google.com'
    },
    valueChanged (Val) {
      this.isChanged = true
    },
    keyMonitor (event) {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        event.target.previousElementSibling.focus()
      } else {
        this.isChanged = true
        event.target.nextElementSibling.focus()
      }
    },
    async verify () {
      this.btnDisabled = true
      const otp = this.pin1 + this.pin2 + this.pin3 + this.pin4
      // if (this.getCountryCode === '971') {
      //   otp += this.pin5
      // }
      const phoneNumber = this.$store.state.phoneNumber
      const ts = this.$store.state.ts
      const ti = this.$store.state.ti
      const referenceId = localStorage.getItem('referenceId')
      const response = await ApiService.verify(
        phoneNumber,
        otp,
        ts,
        ti,
        referenceId
      )
      const self = this
      if (this.getCountryCode === '964') {
        if (response && response.status === 200) {
          if (response.data.data !== '') {
            localStorage.setItem('highFitToken', response.data.access_token)
            ApiService.headers = {
              Authorization: 'Bearer ' + response.data.access_token,
              lang: localStorage.getItem('lang')
            }
            this.$store.commit('setPoints', response.data.user.points)
            self.$router.push('/')
          } else {
            AlertServie.info(response.data.msg)

            if (response.status === 200) {
              this.checkUserStatusInterval = setInterval(async function () {
                const response = await ApiService.checkUserStatus(phoneNumber)
                if (response && response.status === 200) {
                  if (
                    response.data.status === 2 ||
                    response.data.status === 1
                  ) {
                    localStorage.setItem(
                      'highFitToken',
                      response.data.access_token
                    )
                    clearInterval(self.checkUserStatusInterval)
                    ApiService.headers = {
                      Authorization: 'Bearer ' + response.data.access_token,
                      lang: localStorage.getItem('lang')
                    }
                    this.$store.commit('setPoints', response.data.user.points)
                    self.$router.push('/')
                    Vue.$forceUpdate()
                  } else {
                    if (response.data.status === 3) {
                      clearInterval(self.checkUserStatusInterval)
                    }
                    AlertServie.info(response.data.msg)
                    this.disabled = false
                    this.btnDisabled = false
                  }
                } else {
                  clearInterval(self.checkUserStatusInterval)
                  AlertServie.info(response.data.msg)
                  this.btnDisabled = false
                  this.disabled = false
                }
              }, 5000)
            }
          }
        } else {
          clearInterval(self.checkUserStatusInterval)
          this.disabled = false
          this.btnDisabled = false
          AlertServie.errorMessage(response.data.msg)
        }
      } else {
        if (
          response.data.data &&
          (response.data.code === 0 ||
            response.data.code === 22 ||
            response.data.code === 123)
        ) {
          if (response.data.data !== '') {
            localStorage.setItem(
              'highFitToken',
              response.data.data.access_token
            )
            ApiService.headers = {
              Authorization: 'Bearer ' + response.data.data.access_token,
              lang: localStorage.getItem('lang')
            }
            this.$store.commit('setPoints', response.data.data.user.points)
            // this.$router.push(
            //   response.data.code === 0 || response.data.code === 123
            //     ? '/thank-you'
            //     : '/'
            // )
            this.$router.push('/thank-you')
          } else {
            AlertServie.info(response.data.messsage)
          }
        }
      }
      this.btnDisabled = false
    }
  },
  computed: {
    getCountryCode: function () {
      return localStorage.getItem('countryCode')
    }
  }
}
</script>
